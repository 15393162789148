<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <!--    Form Dialog-->
    <v-dialog
      v-model="dialog"
      max-width="400px"
    >
      <v-card
        class="mx-auto"
      >
        <v-toolbar flat>
          <v-toolbar-title class="grey--text">
            <span class="text-h3">{{ formTitle }}</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="close"
          >
            <v-icon class="red--text">
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="editedItem.name"
                  placeholder="Branch  Name"
                  outlined
                  label="Branch  Name"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="editedItem.address"
                  placeholder="Address"
                  outlined
                  label="Address"
                  @keyup.enter="onAddBranch"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            :loading="loader"
            color="primary"
            @click="onAddBranch"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    Form Dialog End-->
    <!--    Delete Dialog Start-->
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete this client?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="closeDelete"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteItemConfirm"
          >
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    Delete Dialog end-->

    <!--    All Dialogs End-->
    <!--    Base Material Start -->
    <div v-if="fetchup">
      <v-skeleton-loader
        type="table-heading, list-item-two-line, image, table-tfoot"
      />
    </div>
    <base-material-card
      v-else
      color="primary"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">
          <v-row class="align-content-md-space-between">
            <v-col
              cols="12"
              md="9"
              sm="6"
            >
              <div class="text-h3 font-weight-light">
                <v-icon
                  left
                  class="primary--text"
                >
                  mdi-domain
                </v-icon> Branch List <i class="text-caption">Total: <b>{{ total }}</b></i>
              </div>

              <div class="text-subtitle-1 font-weight-light">
                Please manage all branches
              </div>
            </v-col>
            <v-col
              md="3"
              sm="6"
              style="text-align:right"
            >
              <v-btn
                color="primary"
                dark
                outlined
                class="mb-2 subheading font-weight-bold"
                @click="openDialog"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                Add Branch
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>
      <v-data-table
        :headers="headers"
        :items="branches"
        sort-by="calories"
        class="elevation-1"
      >
        <v-skeleton-loader
          type="table-heading, list-item-two-line, image, table-tfoot"
        />
        <!--        <template v-slot:item.actions="{ item }">-->
        <!--          <v-btn-->
        <!--            small-->
        <!--            text-->
        <!--            class="grey&#45;&#45;text"-->
        <!--            @click="editItem(item)"-->
        <!--          >-->
        <!--            <v-icon-->
        <!--              small-->
        <!--              class="mr-2"-->
        <!--            >-->
        <!--              mdi-pencil-->
        <!--            </v-icon>-->
        <!--            Edit-->
        <!--          </v-btn>-->
        <!--          <v-btn-->
        <!--            small-->
        <!--            text-->
        <!--            class="red&#45;&#45;text"-->
        <!--            @click="deleteItem(item)"-->
        <!--          >-->
        <!--            <v-icon-->
        <!--              small-->
        <!--              class="mr-2"-->
        <!--            >-->
        <!--              mdi-delete-->
        <!--            </v-icon>-->
        <!--            Delete-->
        <!--          </v-btn>-->
        <!--        </template>-->
        <template v-slot:no-data>
          <v-btn
            text
            small
            color="primary"
            @click="fetchBranch"
          >
            <v-icon
              class="primary--text"
              left
            >
              mdi-refresh
            </v-icon>Reset
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
    <!--    Base Material End-->
  </v-container>
</template>
<script>
  /* eslint-disable */
  const axios = require('axios')
  export default {
    name: 'ManageBranch',
    data: () => ({
      menu: false,
      dialog: false,
      fetchup: true,
      loader:false,
      dialogDelete: false,
      err:[],
      headers: [
        {
          text: 'Branch Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
      ],
      branches: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        address:'',
      },
      defaultItem: {
        name: '',
        address:''
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Add New Branch' : 'Edit Branch'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    mounted () {
      this.fetchBranch()
    },

    methods: {
      openDialog(){
        this.dialog = true;
      },
      fetchBranch () {
        this.fetchup = true
        let cid = this.userData.company._id;
        const self = this
        axios.get(this.apiSet.companies+'/'+ cid)
          .then(response => {
            const obj = response.data.company.branches
            self.branches = obj
            self.total = obj.length
            self.fetchup = false
            console.log({message: obj});
          }).catch(
          e => {
            self.fetchup = false
            console.log(e)
          },
        )
      },
      onAddBranch() {
        if (this.editedIndex > -1) {
          this.updateBranch()
        } else {
          const self = this
          // this.$validator.validateAll().then((result) => {
          //   if (result) {
          this.loader = true
          const catData = this.editedItem
          let cid = this.userData.company._id;


          axios.post(this.apiSet.companies+'/'+cid+'/branches', {
            name: catData.name,
            address: catData.address,
          })
            // axios.post(this.apiSet.branches, {name:catData.name,price: parseFloat(catData.price)
            .then(function (response) {
              self.close()
              self.fetchBranch()
              self.loader = false
              this.$store.commit('setSnack', { t: 'Success. Data was successfully added.', c: 'green darken-3' })
            })
            .catch(error => {
              self.loader = false
              self.err.push(error)
            })
          // } else {
          //   self.snack = true
          //   self.snackText = 'Error. Please correct form errors.'
          //   self.snackColor = 'red'
          //   return false
          // }
          // })
        }
      },
      updateBranch () {
        const self = this
        this.loader = true
        const catData = this.editedItem
        let cid = this.userData.company._id;


        axios.put(this.apiSet.companies+'/'+cid+'/branches', {
            name: catData.name,
            address: catData.address,
          })
          .then(function (response) {
            self.close()
            self.fetchBranch()
            self.loader = false
            self.$store.commit('setSnack', { t: 'Success. Data was successfully updated.', c: 'green darken-3' })
          })
          .catch(error => {
            self.loader = false
            self.err.push(error)
          })
      },

      editItem (item) {
        this.editedIndex = this.branches.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.branches.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.branches.splice(this.editedIndex, 1)
        const self = this
        this.loader = true
        const delId = this.editedItem._id

        axios.delete(this.apiSet.facility+'/'+delId)
          .then(function (response) {
            self.loader = false
            self.$store.commit('setSnack', { t: 'Success. Data was successfully deleted.', c: 'green darken-3' })
            self.closeDelete()

          })
          .catch(error => {
            self.loader = false
            self.err.push(error)
          })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.branches[this.editedIndex], this.editedItem)
        } else {
          this.branches.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>

